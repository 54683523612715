import React, { Fragment, memo, useContext, useEffect, useRef, useState } from 'react';
import type { FC } from 'react';

import type {
	AnnotationByMatches,
	InlineCommentSelectionComponentProps,
} from '@atlaskit/editor-common/types';
import type { AddMarkStep } from '@atlaskit/editor-prosemirror/transform';

import {
	ADD_INLINE_COMMENT_EXPERIENCE,
	ADD_INLINE_COMMENT_LOAD_EXPERIENCE,
	ADD_INLINE_COMMENT_PUBLISH_EXPERIENCE,
	startEditorExperiences,
} from '@confluence/experience-tracker';
import {
	InlineCommentMode,
	InlineCommentFramework,
} from '@confluence/inline-comments-common/entry-points/enum';
import type { CommentCreationLocation } from '@confluence/inline-comments-queries';
import { HighlightActions } from '@confluence/highlight-actions';
import { ReattachCommentContext } from '@confluence/comment-context';

import { CreateComment } from '../CreateComment';

export type SelectionComponentProps = {
	pageId: string;
	isArchived?: boolean;
	showCreateComponent: boolean;
	showHighlightActions: boolean;
	selectionOptions: any;
	stepGenerationError: boolean;
	setShowCreateComponent: (showCreateComponent: boolean) => void;
	setShowHighlightActions: (showHighlightActions: boolean) => void;
	handleSaveSuccess: (annotationId: string) => void;
	setSelectionOptionsForCreateEditor: () => void;
	handleClose: () => void;
	contentType?: 'page' | 'blogpost' | 'whiteboard' | 'database' | 'embed';
	lastModifiedDate?: string | null;
};

export type SelectionOptions = AnnotationByMatches & {
	createdFrom: CommentCreationLocation;
	step?: AddMarkStep;
	inlineNodeTypes?: string[];
	targetNodeType?: string;
};

export const SelectionComponent: FC<
	InlineCommentSelectionComponentProps & SelectionComponentProps
> = memo(
	({
		pageId,
		isArchived,
		range,
		draftRange,
		isAnnotationAllowed,
		wrapperDOM,
		setSelectionOptionsForCreateEditor,
		handleSaveSuccess,
		handleClose,
		showCreateComponent,
		showHighlightActions,
		stepGenerationError,
		setShowCreateComponent,
		setShowHighlightActions,
		selectionOptions,
		contentType,
		inlineNodeTypes,
		lastModifiedDate,
	}) => {
		const { isInReattachMode } = useContext(ReattachCommentContext);
		const annotationElement = useRef<HTMLElement | null>(null);
		const prevPageIdRef = useRef(pageId);
		const [allowAnnotationOnSelection, setAllowAnnotationOnSelection] =
			useState(isAnnotationAllowed);

		useEffect(() => {
			// If we're in reattach mode or we have no selection do nothing
			if (isInReattachMode() || !range) {
				setShowHighlightActions(false);
				return;
			}

			const { startContainer, endContainer } = range;
			let nodeToCheck: HTMLElement | null = null;

			// If we're highlighting an inline-extension, don't allow annotations
			// Check the startContainer first
			if (startContainer.nodeType === Node.TEXT_NODE) {
				nodeToCheck = startContainer.parentElement;
			} else {
				nodeToCheck = startContainer as HTMLElement;
			}

			if (nodeToCheck && nodeToCheck.closest('.ak-renderer-extension')) {
				setAllowAnnotationOnSelection(false);
				setShowHighlightActions(true);
				return;
			}

			// Next check the endContainer if the start wasn't on an inline-extension
			if (endContainer.nodeType === Node.TEXT_NODE) {
				nodeToCheck = endContainer.parentElement;
			} else {
				nodeToCheck = endContainer as HTMLElement;
			}

			if (nodeToCheck && nodeToCheck.closest('.ak-renderer-extension')) {
				setAllowAnnotationOnSelection(false);
			} else {
				// If everything passes, set it to what was passed by the annotationProvider
				setAllowAnnotationOnSelection(isAnnotationAllowed);
			}

			setShowHighlightActions(true);
		}, [
			range,
			isAnnotationAllowed,
			isInReattachMode,
			setShowHighlightActions,
			setAllowAnnotationOnSelection,
		]);

		// TODO: Fix me
		// This currently doesn't do anything to position the sidebar
		// We SHOULD be calculating this annotationElement and positioning based on it
		useEffect(() => {
			if (!draftRange) {
				return;
			}

			const rangeContainer = draftRange?.startContainer;

			if (rangeContainer) {
				if (rangeContainer.nodeType === Node.TEXT_NODE) {
					annotationElement.current = rangeContainer.parentElement;
				} else {
					annotationElement.current = rangeContainer as HTMLElement;
				}
			}
		}, [draftRange]);

		useEffect(() => {
			const showCreateComponent = Boolean(selectionOptions);

			if (showCreateComponent) {
				startEditorExperiences(
					{
						name: ADD_INLINE_COMMENT_EXPERIENCE,
						attributes: {
							mode: InlineCommentMode.VIEW,
							framework: InlineCommentFramework.ANNOTATION_PROVIDER,
						},
					},
					{
						name: ADD_INLINE_COMMENT_LOAD_EXPERIENCE,
					},
					{
						name: ADD_INLINE_COMMENT_PUBLISH_EXPERIENCE,
					},
				);
			}

			setShowCreateComponent(showCreateComponent);
		}, [selectionOptions, setShowCreateComponent]);

		useEffect(() => {
			const prevPageId = prevPageIdRef.current;

			// Whenever the pageId changes, we need to close anything that's open
			if (pageId !== prevPageId) {
				handleClose();
			}

			return () => {
				if (pageId !== prevPageId) {
					handleClose();
				}
			};
			// We only want to listen to the pageId changing
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [pageId]);

		// Show an error message if the generation of a step caused an error
		if (stepGenerationError) {
			// Do something error-y
		}

		return (
			<Fragment>
				{showCreateComponent && (
					<CreateComment
						pageId={pageId}
						annotationElement={wrapperDOM}
						selectionOptions={selectionOptions}
						onCreate={handleSaveSuccess}
						onClose={handleClose}
					/>
				)}
				{range && showHighlightActions && (
					<HighlightActions
						contentId={pageId}
						range={range}
						isAnnotationAllowed={allowAnnotationOnSelection}
						onCommentButtonPress={setSelectionOptionsForCreateEditor}
						isArchived={isArchived}
						contentType={contentType}
						inlineNodeTypes={inlineNodeTypes}
						isFabric
						lastModifiedDate={lastModifiedDate}
					/>
				)}
			</Fragment>
		);
	},
);
