import React, { memo, useCallback, useContext, useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import uuid from 'uuid/v4';

import { AnnotationUpdateEvent } from '@atlaskit/editor-common/types';
import type {
	AnnotationByMatches,
	InlineCommentHoverComponentProps,
} from '@atlaskit/editor-common/types';
import type { JSONDocNode } from '@atlaskit/editor-json-transformer';
import type { AddMarkStep } from '@atlaskit/editor-prosemirror/transform';

import { CommentCreationLocation } from '@confluence/inline-comments-queries';
import { getRendererAnnotationEventEmitter } from '@confluence/annotation-event-emitter';
import { ReattachCommentContext } from '@confluence/comment-context';
import { HighlightActionsSimple } from '@confluence/highlight-actions';
import { useBooleanFeatureFlag } from '@confluence/session-data';

import { CreateComment } from '../CreateComment';

export type HoverComponentProps = {
	pageId: string;
	updateDocument?: (doc: JSONDocNode) => void;
	isArchived?: boolean;
};

export type SelectionOptions = AnnotationByMatches & {
	createdFrom: CommentCreationLocation;
	step?: AddMarkStep;
	targetNodeType?: string;
};

const findAnnotationElement = (target: HTMLElement) => {
	// Scan through the parents to the root of the renderer looking for an annotation mark. If we find one then the
	// comment pressing the comment button will instead cause the comment view to open the discovered annotation.
	let element = target.parentElement;
	while (element && !element.classList.contains('ak-renderer-wrapper')) {
		if (
			element.dataset.markType === 'annotation' &&
			element.dataset.markAnnotationState !== 'resolved'
		) {
			return element;
		}
		element = element.parentElement;
	}

	return null;
};

export const HoverComponent: FC<InlineCommentHoverComponentProps & HoverComponentProps> = memo(
	({
		pageId,
		updateDocument,
		wrapperDOM,
		range,
		isWithinRange,
		isAnnotationAllowed,
		onCreate,
		onClose,
		applyDraftMode,
		removeDraftMode,
	}) => {
		const showHighlightActionsTimeoutRef = useRef<NodeJS.Timeout>();
		const annotationId = useRef<string>('');
		const annotationExists = useRef<boolean>(false);
		const annotationElement = useRef<HTMLElement | null>(null);
		const [showHighlightActions, setShowHighlightActions] = useState(false);
		const [showCreateComponent, setShowCreateComponent] = useState(false);
		const [selectionOptions, setSelectionOptions] = useState<SelectionOptions | undefined>();
		const [stepGenerationError, setStepGenerationError] = useState(false);
		const { isInReattachMode } = useContext(ReattachCommentContext);
		const [isOnCommentButton, setIsOnCommentButton] = useState(false);
		const includePageFixedEnabled = useBooleanFeatureFlag(
			'confluence.frontend.comments-on-media.bug.include.page_tnawt',
		);
		useEffect(() => {
			// If we're in reattach mode or we have no selection do nothing
			if (isInReattachMode() || !range) {
				return;
			}

			if (showHighlightActionsTimeoutRef.current) {
				clearTimeout(showHighlightActionsTimeoutRef.current);
			}

			showHighlightActionsTimeoutRef.current = setTimeout(() => {
				setShowHighlightActions(true);

				const dismissHighlight = () => {
					setShowHighlightActions(false);
					document.querySelector('#content-body')?.removeEventListener('click', dismissHighlight);
				};

				document.querySelector('#content-body')?.addEventListener('click', dismissHighlight);
			}, 500);
		}, [range, showHighlightActionsTimeoutRef, isInReattachMode]);

		let isInPageInclude = null;
		if (range.startContainer instanceof HTMLElement) {
			isInPageInclude =
				includePageFixedEnabled && range.startContainer.closest('[data-node-type="include"]');
		}

		useEffect(() => {
			const annotation = findAnnotationElement(range.startContainer as HTMLElement);
			if (annotation?.dataset.id) {
				annotationId.current = annotation.dataset.id;
				annotationExists.current = true;
			} else {
				annotationId.current = uuid();
				annotationExists.current = false;
			}

			// TODO: Remove this once wrapperDom works correctly
			if (range.startContainer.nodeType === Node.TEXT_NODE) {
				annotationElement.current = range.startContainer.parentElement;
			} else {
				annotationElement.current = range.startContainer as HTMLElement;
			}
		}, [range]);

		useEffect(() => {
			// Whenever the pageId changes, we need to close anything that's open
			return () => {
				handleClose();
			};
			// We only want to listen to the pageId changing
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [pageId]);

		const onCommentButtonPress = () => {
			if (annotationExists.current) {
				// Tell the renderer to set the existing comment to active rather then creating a new comment
				getRendererAnnotationEventEmitter().emit(AnnotationUpdateEvent.ON_ANNOTATION_CLICK, {
					annotationIds: [annotationId.current],
					eventTarget: document.getElementById(annotationId.current),
					// use mediaSingle here to align with annotation viewed event dispatched in editor
					eventTargetType: 'mediaSingle',
					viewMethod: 'commentButton',
				});
				handleClose();
				return;
			}

			const result = applyDraftMode({
				annotationId: annotationId.current,
				keepNativeSelection: false,
			});

			if (result) {
				setSelectionOptions({
					step: result.step as AddMarkStep,
					originalSelection: result.originalSelection,
					numMatches: result.numMatches,
					matchIndex: result.matchIndex,
					createdFrom: CommentCreationLocation.RENDERER,
					targetNodeType: result.targetNodeType,
				});
			} else {
				setStepGenerationError(true);
			}

			setShowCreateComponent(true);
		};

		const handleSaveSuccess = (aId: string) => {
			setStepGenerationError(false);
			setSelectionOptions(undefined);

			// Since we generated the uuid ourselves, we need to replace the document generated with
			// the annotationId that we received from the backend so things line up correctly
			const result = onCreate(aId);

			if (result) {
				removeDraftMode();
				updateDocument && updateDocument(result.doc);

				setTimeout(() => {
					// Tell the renderer to set the new comment to active
					getRendererAnnotationEventEmitter().emit(AnnotationUpdateEvent.ON_ANNOTATION_CLICK, {
						annotationIds: [aId],
						// eventTarget: wrapperDOM,
						eventTarget: document.getElementById(aId),
					});
					handleClose();
					// We need the timeout to give the document the chance to make the marks properly
				}, 1000);
			}
		};

		const handleClose = useCallback(() => {
			setShowCreateComponent(false);
			setStepGenerationError(false);
			setSelectionOptions(undefined);
			onClose();
		}, [setShowCreateComponent, setStepGenerationError, setSelectionOptions, onClose]);

		// Show an error message if the generation of a step caused an error
		if (stepGenerationError) {
			// Do something error-y
		}

		if (showCreateComponent) {
			return (
				<CreateComment
					pageId={pageId}
					annotationElement={wrapperDOM}
					selectionOptions={selectionOptions}
					onCreate={handleSaveSuccess}
					onClose={handleClose}
				/>
			);
		}

		// We should auto dismiss the comment button when mouse is not within the hover target and not on the comment button
		const shouldDismiss = !isWithinRange && !isOnCommentButton;

		return !isInPageInclude && showHighlightActions && !shouldDismiss ? (
			<HighlightActionsSimple
				contentId={pageId}
				range={range}
				isAnnotationAllowed={isAnnotationAllowed}
				onCommentButtonPress={onCommentButtonPress}
				needTransition={false}
				onMouseEnter={() => {
					setIsOnCommentButton(true);
				}}
				onMouseLeave={() => {
					setIsOnCommentButton(false);
				}}
			/>
		) : null;
	},
);
