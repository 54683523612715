/* eslint-disable @atlaskit/design-system/ensure-design-token-usage/preview */
import type { FC } from 'react';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
// We have deprecated emotion. Please use compiled instead
// eslint-disable-next-line no-restricted-imports, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';

import { token } from '@atlaskit/tokens';
import EditorLinkIcon from '@atlaskit/icon/glyph/editor/link';
import { N200 } from '@atlaskit/theme/colors';

import { HIGHLIGHT_CLASS } from '@confluence/comments-util';

/*
 * By the time our component loads in the tree, the Renderer component has already
 * parsed the ADF and rendered the contents of the page to the DOM with the highlights
 * for Inline Comments already created as <span> elements. However, they don't have any
 * styling on them, so we need to use querySelector() to gain access to these elements
 * and add our own styles and click handlers to them to present expected interaction
 */

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
const HyperlinkPopupContainer = styled.div`
	position: absolute;
	top: -50px;
	left: -50px;
	width: 200px;
	background: ${token('elevation.surface', 'white')};
	border-radius: 4px;
	box-shadow: ${token('elevation.shadow.raised', '0 1px 2px rgba(0, 0, 0, 0.2)')};
	box-sizing: content-box; /* do not set this to border-box or it will break the overflow handling */
	padding: ${token('space.100', '8px')} ${token('space.150', '12px')};

	span:first-of-type {
		text-decoration: none;
		color: ${token('color.text.subtle', N200)};
		padding-right: 5px;
		line-height: inherit;

		svg {
			padding-bottom: ${token('space.025', '2px')};
		}
	}
`;

const PANEL_LOOKUP_THROTTLE = 1000;

// We need to truncate links that are longer than 20 characters so they fit in the line
const truncateLink = (link: any) => (link.length > 19 ? `${link.substr(0, 20)}...` : link);

export const InlineCommentLinkHandler: FC = memo(() => {
	const [hasPanelLoaded, setHasPanelLoaded] = useState(false);
	const contentBodyRef = useRef<HTMLDivElement | null>(null);
	const hoveredAnchorRef = useRef<HTMLAnchorElement | null>(null);
	const hideTimeoutRef = useRef<NodeJS.Timeout | null>(null);
	const [popUpLink, setPopUpLink] = useState<string | null>(null);

	useEffect(() => {
		// This tag is the same for both fabric and tiny pages
		contentBodyRef.current = document.querySelector('#content');
	}, []);

	useEffect(() => {
		if (contentBodyRef.current) {
			let throttledPanelLookupHandle: null | number = null;
			// Event handlers attaching hover behavior to links are happening _before_ Panel macro loads
			// Re-run `handleMouseEnter` after Panel element is loaded in the DOM
			const panelSelector = '.ak-editor-panel';
			const observer = new MutationObserver((mutationsList) => {
				const hasChildListMutation = mutationsList.some((m) => m.type === 'childList');
				if (hasChildListMutation && !throttledPanelLookupHandle) {
					throttledPanelLookupHandle = window.setTimeout(() => {
						throttledPanelLookupHandle = null;
						const element = document.querySelector(panelSelector);
						if (element) {
							observer.disconnect();
							if (!hasPanelLoaded) {
								setHasPanelLoaded(true);
								// Once one Panel macro has loaded, we know that the rest of the Panel macros on the page has loaded. There's no need to iterate through the rest
								// of the mutations list. State change here will re-trigger the hook that attaches event handlers to all links, including those in the Panel macro.
							}
						}
					}, PANEL_LOOKUP_THROTTLE);
				}
			});

			observer.observe(contentBodyRef.current, {
				childList: true,
				subtree: true,
			});

			return () => {
				observer.disconnect();
				if (throttledPanelLookupHandle) {
					window.clearTimeout(throttledPanelLookupHandle);
				}
			};
		}
	}, [hasPanelLoaded]);

	const handleMouseLeave = useCallback(
		(e: any) => {
			const { target } = e;

			target.removeEventListener('mouseleave', handleMouseLeave);
			hideTimeoutRef.current = setTimeout(() => {
				hoveredAnchorRef.current = null;
				setPopUpLink(null);
			}, 2000);
		},
		[hideTimeoutRef, hoveredAnchorRef],
	);

	const handleMouseEnter = useCallback(
		(e: any) => {
			const { target } = e;
			let isOverHighlight = false;

			for (const element of target.children) {
				// Only add this handler to links WITH comments on them and ignore block level annotations
				if (
					element.classList.contains(HIGHLIGHT_CLASS) ||
					(element.getAttribute('data-mark-annotation-type') === 'inlineComment' &&
						element.getAttribute('data-mark-annotation-state') !== 'resolved' &&
						element.getAttribute('data-block-mark') !== 'true')
				) {
					isOverHighlight = true;
					break;
				}
			}

			if (isOverHighlight) {
				const link = truncateLink(target.getAttribute('href'));

				// Don't recalculate if we're showing the same pop-up
				if (link === popUpLink) {
					return;
				}

				if (hideTimeoutRef.current) {
					clearTimeout(hideTimeoutRef.current);
				}

				target.setAttribute('style', 'position: relative');
				hoveredAnchorRef.current = target;
				target.addEventListener('mouseleave', handleMouseLeave);

				setPopUpLink(link);
			}
		},
		[hideTimeoutRef, hoveredAnchorRef, popUpLink, handleMouseLeave],
	);

	useEffect(() => {
		if (!contentBodyRef.current) {
			return;
		}

		// Set up the event handler
		contentBodyRef.current
			?.querySelectorAll('a[href]')
			.forEach((anchor) => anchor.addEventListener('mouseenter', handleMouseEnter));

		return () => {
			// Remove the event listener
			contentBodyRef.current
				?.querySelectorAll('a[href]')
				.forEach((anchor) => anchor.removeEventListener('mouseenter', handleMouseEnter));
		};
	}, [hasPanelLoaded, contentBodyRef, handleMouseEnter]);

	return popUpLink && hoveredAnchorRef.current
		? createPortal(
				<HyperlinkPopupContainer>
					<EditorLinkIcon
						size="medium"
						label="inline-comment-hyperlink-icon"
						primaryColor={token('color.icon.accent.gray', N200)}
					/>
					{popUpLink}
				</HyperlinkPopupContainer>,
				hoveredAnchorRef.current,
			)
		: null;
});
